









import { Component, Vue } from 'vue-property-decorator'
import GroupTable from '@/components/organisms/GroupSetting/GroupTable.vue'
import AddModal from '@/components/organisms/GroupSetting/AddModal.vue'
import menuStore from '@/store/Menu'
import groupSettingsStore from '@/store/GroupSettings'
import contractsStore from '@/store/Contracts'

@Component({ components: { GroupTable, AddModal } })
export default class extends Vue {
  async mounted(): Promise<void> {
    // 初期化
    menuStore.setActiveFlg({ settings: true })
    await Promise.all([
      groupSettingsStore.fetchOrganizationGroups(),
      contractsStore.fetchLatestContract(),
      contractsStore.fetchFutureContract(),
    ])
  }

  get maxGroups(): number | null {
    const latestMaxGroups = contractsStore.latestContract?.plan?.maxGroups
    if (latestMaxGroups === undefined) {
      return 0
    }

    const futureMaxGroups = contractsStore.futureContract?.plan?.maxGroups
    if (futureMaxGroups === undefined) {
      return latestMaxGroups
    }

    if (latestMaxGroups === null || futureMaxGroups === null) {
      return null
    } else if (latestMaxGroups >= futureMaxGroups) {
      return latestMaxGroups
    } else {
      return futureMaxGroups
    }
  }
}
