




































import { Component, Prop, Vue } from 'vue-property-decorator'
import VABox from 'va/widgets/VABox.vue'
import DeleteModal from '@/components/organisms/GroupSetting/DeleteModal.vue'
import Status from '@/components/atoms/Group/Status.vue'
import groupSettingsStore, { OrganizationGroup, Status as OrgGroupStatus } from '@/store/GroupSettings'

@Component({ components: { VABox, DeleteModal, Status } })
export default class extends Vue {
  @Prop({ type: Number }) maxGroups!: number | null

  isDeleted(status: string): boolean {
    return status === OrgGroupStatus.DELETED
  }

  groupName(name: string, status: string): string {
    return this.isDeleted(status) ? '-' : name
  }

  invitationId(invitationId: string, status: string): string {
    return this.isDeleted(status) ? '-' : invitationId
  }

  get groupData(): OrganizationGroup[] {
    return groupSettingsStore.organizationGroups
  }

  get blankData(): number[] {
    if (this.maxGroups === null) {
      return []
    }

    const diff = this.maxGroups - this.groupData.length
    return Array<number>(diff > 0 ? diff : 0)
  }
}
