































import { Component, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import Modal from '@/components/molecules/Modal.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import groupSettingStore, { OrganizationGroup } from '@/store/GroupSettings'

@Component({ components: { Modal, Spinner } })
export default class extends Vue {
  @Prop({ type: Number }) maxGroups!: number | null

  isSuccess = false
  isLoading = false
  invitationId = ''
  errorMessage = ''
  modalClose(): void {
    const modal = this.$refs.modal as Modal
    modal.modalClose()
    this.isSuccess = false
  }
  checkInvitationIdDebounce?: () => void
  get groupName(): string {
    return groupSettingStore.invitedCheckedGroup.name
  }

  get groupData(): OrganizationGroup[] {
    return groupSettingStore.organizationGroups
  }

  get isAddButtonDisabled(): boolean {
    if (this.maxGroups === null) {
      return false
    }
    return this.groupData.length >= this.maxGroups
  }

  checkInvitationId(): void {
    groupSettingStore.setInvitedCheckedGroup({ name: '' })
    this.errorMessage = ''
    if (!this.checkInvitationIdDebounce) {
      this.checkInvitationIdDebounce = _.debounce(() => {
        groupSettingStore.checkInvitationId({ invitationId: this.invitationId }).catch((e: Error) => {
          this.errorMessage = e.message
        })
      }, 500)
    }
    this.checkInvitationIdDebounce()
  }
  async addGroup(): Promise<void> {
    this.isLoading = true
    try {
      await groupSettingStore.applyViewPermission({ invitationId: this.invitationId })
      groupSettingStore.fetchOrganizationGroups()
      this.invitationId = ''
      groupSettingStore.setInvitedCheckedGroup({ name: '' })
      this.isSuccess = true
    } catch (e) {
      const error = e as Error
      this.errorMessage = error.message
    } finally {
      this.isLoading = false
    }
  }
}
